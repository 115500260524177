import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import 'popper.js';

import './bootstrap/util.js';
import './bootstrap/alert.js';
import './bootstrap/button.js';
import './bootstrap/carousel.js';
import './bootstrap/collapse.js';
import './bootstrap/dropdown.js';
import './bootstrap/modal.js';
import './bootstrap/tab.js';
import './bootstrap/index.js';


import './jquery/jquery-ui'; 
import './jquery/jquery.autocomplete';
import './jquery/jquery.validate.min';


// View Product Page
import './jquery/jquery.easing.1.3';
import './jquery/jquery.form';

//import './jquery/jquery.datetimepicker.full.min';



//import './bundle/lightcase/jquery.events.touch';
import './bundle/lightcase/lightcase';
//import './bundle/matchHeight/jquery.matchHeight';
import './bundle/priceRange/priceRange';
import './bundle/slick/slick'; 

import './bundle/typeahead/typeaheadBundle';  

import Mustache from'./bundle/mustache/mustache.min';


import './cookieConsent/cookieConsent';
import './cookieConsent/cookieOptions';

import { OverlayScrollbars } from 'overlayscrollbars';

import './bundle/webp/webp';


// Passive event listeners
jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
            this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
            this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
}; 


var is_mobile = false;

var screenSize = $(window).width();


$(document).ready(function () {

    // Mobile menu menu ================================

        var $wrapper = $('#page-wrapper'),
            $drawer = $('#nav-mobile'),
            $button = $('#nav-skip'),
            toggleDrawer = function () {
                $wrapper.toggleClass("active");
                $drawer.toggleClass("active");
                $button.toggleClass('toggled');    
            };

        $('#page_wrapper > .main-content').on('click', function () {
            if ($wrapper.hasClass('active')) toggleDrawer();  
        });
        $button.on('click', function (e) { 
            e.preventDefault();
            toggleDrawer();
        });

        var width = $(window).width();
        $(window).on('resize', function () {
            if ($(window).width() !== width) {  
                if ($wrapper.hasClass('active')) toggleDrawer();
                width = $(window).width(); 
            }
        });

        $(document).on('click', function (e) {
            if ($(e.target).hasClass("page-wrapper") && $(e.target).hasClass("active")) {
                toggleDrawer();
            }
        });



        var header = document.querySelector(".site-header");

        if(header){
        
            var fixHeader = function(){
        
                // Find out height of header element
                var headerHeight = header.offsetHeight;
        
                // Set --header-height custom property
                document.documentElement.style.setProperty("--header-height", headerHeight + "px");
        
                // Add class when scrolling on windows >= 768px
                var windowWidth = document.body.clientWidth;
        
                if(windowWidth > 991){
        
                    // Get scroll position
                    let scroll = window.scrollY;
        
                    // Toggle class
                    if (scroll > 50) {
                        header.classList.add("scrolling");
                    } else {
                        header.classList.remove("scrolling");
                    } 
                }
        
            };
        
            // Run on scroll/load/resize
            window.addEventListener("scroll", fixHeader, false);
            window.addEventListener("load", fixHeader, false);
            window.addEventListener("resize", fixHeader, false);
        }   


    // Main menu drop downs on hover ====================        
    
        // Hover for Bootstrap Menus

        // * must add class '.dropdown-hover' to the data-toggle's parent

        /*
        $('.dropdown-hover').hover(function(){
            $(this).children('[data-toggle="dropdown"]').click();
        }, function(){
            $(this).children('[data-toggle="dropdown"]').click();
        });
        */
       
       $( ".dropdown" )
         .mouseover(function() {
           $( this ).addClass('show').attr('aria-expanded',"true");
           $( this ).find('.dropdown-menu').addClass('show');
         })
         .mouseout(function() {
           $( this ).removeClass('show').attr('aria-expanded',"false");
           $( this ).find('.dropdown-menu').removeClass('show');
         });        


    // MatchHeight =====================================
    //$('.main-nav .nav-link').matchHeight({ byRow: false });
    //$('.prod-box h3').matchHeight({ byRow: true });
    //$('.checkout-payments .holder').matchHeight({ byRow: true }); 


    
    if (!is_mobile) {
        var searchQuery = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.whitespace,
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            //identify: function(obj) { return obj.id; },
            //limit: 10,
            remote: {
                url: '/ajax/search?q=%QUERY',
                wildcard: '%QUERY'
            }
        });

        $("#hdsearch").typeahead({
            hint: true,
            highlight: true,
            minLength: 2
        }, {
                name: 'searchQuery',
                limit: "110", //The first '1' is ignored for some fucking reason! :/
                display: 'value',
                source: searchQuery,
                templates: {
                    empty: [
                        '<div class="empty-message" style="padding: 10px;">Unable to find any products matching your search query.</div>'
                    ],
                    suggestion: function (data) {
                        if(data.imgId!="0"){
                            return Mustache.render('<div><table width="100%"><tr><td width="50"><span class="as-image">{{#imgId}}<img src="https://www.applianceelectronics.co.uk//product_pics/{{imgId}}-thumb.jpg" class="img-responsive" />{{/imgId}}</span></td><td>{{name}}</td><td align="right"><span class="as-price">{{price}}</span></td></tr></table></div>', data)
                        }else{
                            return Mustache.render('<div><table width="100%"><tr><td width="50"><span class="as-image"></span></td><td>{{name}}</td><td align="right"><span class="as-price">{{price}}</span></td></tr></table></div>', data)
                        }
                    },
                    /*footer: function(data){
                        var totalRecords = data.suggestions[0].total;
                        return '<div style="  padding: 8px 8px 0px 4px; text-align: right; font-size: 12px; color: #555555;">Found ' + totalRecords + ' results. <a href="/search?q=' + data.query + '">View all</a></div>';	
                    }*/
                }
            }).on('typeahead:selected', function (evt, item) {
                window.location = item.url;
            });
    }




    // Animate Carousel Items ==========================

    jQuery(function($) {

        var $myCarousel = $('#homepage-carousel');

        // Initialize carousel
        $myCarousel.carousel();


        function doAnimations(elems) {
            var animEndEv = 'webkitAnimationEnd animationend';

            elems.each(function () {
                var $this = $(this),
                    $animationType = $this.data('animation');

                // Add animate.css classes to
                // the elements to be animated 
                // Remove animate.css classes
                // once the animation event has ended
                $this.addClass($animationType).one(animEndEv, function () {
                    $this.removeClass($animationType);
                });
            });
        }

        // Select the elements to be animated
        // in the first slide on page load
        var $firstAnimatingElems = $myCarousel.find('.slide-bg:first')
            .find('[data-animation ^= "animated"]');

        // Apply the animation using our function
        doAnimations($firstAnimatingElems);

        // Pause the carousel 
        $myCarousel.carousel('pause');

        // Attach our doAnimations() function to the
        // carousel's slide.bs.carousel event   
        $myCarousel.on('slide.bs.carousel', function (e) {
            // Select the elements to be animated inside the active slide 
            var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
            doAnimations($animatingElems);
        });

    });


    // Homepage Filter =================================

    var cat_text = $('.cat-select span').text();
    var type_text = $('.type-select span').text();
    var brand_text = $('.brand-select span').text();
    $("#searchprodbutton").attr("disabled", true);
    
    $("#filter_reset_home").click(function(e){
        e.preventDefault();
        $("#searchprodbutton").addClass('disabled');
        $("#searchprodbutton").attr("disabled", true);

        $('.type-select').addClass('disabled');
        $('.type-select').removeClass('picked');
        $('.type-select span').html('Select Type');   
        
        $('.brand-select').addClass('disabled');                                 
        $('.brand-select').removeClass('picked');
        $('.brand-select span').html('Select Brand');   
        
        $('.cat-option').removeClass('selected'); 
        $('.cat-select').removeClass('picked');
        $('.cat-select span').html('Select a Category');   

        $("#searchprodbutton").removeClass('btn-glow');


        $("#hmmasterId").val('');
        $("#hmcatId").val('');
        $("#hmbrandId").val('');
        $("#brandprods").val('');
        $("#masterprods").val('');
        $("#catprods").val('');

        $("#searchprodbutton").html("<span>Search</span>");
        
        // price range slider
        $("#slider").slider({ min: 0, max: 5000, range: true, values: [0, 5000], step: 500
            , slide: function( event, ui ) {
            $( "#pricefrom" ).val(ui.values[0]);
            $( "#priceto" ).val(ui.values[1]);}
         }).slider("pips", {rest: "label", step: 2}).slider("float");
    });

    // Close tab
    function closeActiveTab(){
        $(".tab-pane.active").removeClass('active');
        $(".filter-holder .nav-link.active").removeClass('active');
        $('.filter-fade').removeClass('active');
        $('.carousel-filter').removeClass('active');
        $('.slide-bg').removeClass('blur');
    }

    $('.cat-option').click(function(e){
        e.preventDefault();
        $(this).toggleClass('selected');
        $("#searchprodbutton").removeClass('btn-glow');
        if($(this).hasClass('selected')){
            var masterid = $(this).data('masterid');
            console.log('MasterId: '+masterid);
            $("#hmmasterId").val(masterid);
            $("#hmcatId").val('');
            $("#hmbrandId").val('');
            $('.type-select').removeClass('disabled');
            $("#searchprodbutton").removeClass('disabled');
            $("#searchprodbutton").attr("disabled", false);

        }else{
            $("#searchprodbutton").addClass('disabled');
            $("#searchprodbutton").attr("disabled", true);
            $('.type-select').addClass('disabled');
            $("#hmmasterId").val('');
            $("#hmcatId").val('');
            $("#hmbrandId").val('');
            $("#brandprods").val('');
            $("#masterprods").val('');
            $("#catprods").val('');
            $("#searchprodbutton").html("<span>Search</span>");
            // price range slider
            $("#slider").slider({ min: 0, max: 5000, range: true, values: [0, 5000], step: 500
                , slide: function( event, ui ) {
                $( "#pricefrom" ).val(ui.values[0]);
                $( "#priceto" ).val(ui.values[1]);}
             }).slider("pips", {rest: "label", step: 2}).slider("float");
        }


        if($('.cat-option.selected').length){
            $('.cat-option').not(this).removeClass('selected'); 
            $('.cat-select span').empty();                              
        }

        else{
            // remove tick
            $('.cat-select').toggleClass('picked');

            // reset type selection                    
            $('.type-option').each(function() {
                if ($(this).hasClass('selected')) {
                    $(this).removeClass('selected'); 
                }
            });                                
            $('.type-select').addClass('disabled');
            $('.type-select').removeClass('picked');

            // reset brand selection 
            $('.brand-option').each(function() {
                if ($(this).hasClass('selected')) {
                    $(this).removeClass('selected'); 
                }
            });  
            $('.brand-select').addClass('disabled');                                 
            $('.brand-select').removeClass('picked'); 

            // reset select text
            $('.cat-select span').text(cat_text);
            $('.type-select span').text(type_text);
            $('.brand-select span').text(brand_text); 
        }         

        $('.cat-option').each(function() {                                             

            if ($(this).hasClass('selected')) {

                // add tick to select to show an option has been selected                
                $('.cat-select').addClass('picked');
               
                $('#v-pills-type').load("/ajax/home_loadtypes?masterid="+masterid, function(){
                    // remove disabled state of next option
                    $('.type-select').removeClass('disabled');    
                    $("#hmbrandId").val('');
                    $("#hmcatId").val('');
                });    

               
                
                
                // remove active state from current select
                $('.cat-select').removeClass('active');

                // add active state to next select
                $('.type-select').addClass('active');

                // open next menu
                $('#v-pills-type').tab('show');                 

                // close current menu
                $('#v-pills-cat').tab('dispose').removeClass('active');

                // replace text with selected                                
                var str = $('.cat-option.selected h4').text();
                $('.cat-select span').empty(); 
                $('.cat-select span').text(str);

                // reset type options
                $('.type-option').removeClass('selected');
                $('.type-select').removeClass('picked'); 
                $('.type-select span').text('Select Type'); 
                
                // reset brand options
                $('.brand-option').removeClass('selected');
                $('.brand-select').removeClass('picked'); 
                $('.brand-select span').text('Select Brand');                
                
            }            
        });

    });

    $('.type-option').click(function(e){
        e.preventDefault();
        $(this).toggleClass('selected');
        $('#v-pills-cat').tab('dispose');

        var catid = $(this).data('catid');
        console.log('catid: '+catid);

        $("#hmcatId").val(catid);
        masterid = $("#hmmasterId").val();

        if($('.type-option.selected').length){
            $('.type-option').not(this).removeClass('selected'); 
            $('.type-select span').empty();                               
        }

        else{
            // remove tick               
            $('.type-select').removeClass('picked');

            // reset brand selection 
            $('.brand-option').each(function() {
                if ($(this).hasClass('selected')) {
                    $(this).removeClass('selected'); 
                }
            });  
            $('.brand-select').addClass('disabled');                                 
            $('.brand-select').removeClass('picked'); 

            // reset select text
            $('.type-select span').text(type_text);
            $('.brand-select span').text(brand_text); 
            $( "#pricefrom" ).val('0');
            $( "#priceto" ).val('20000');
        }   

        $('.type-option').each(function() {
            if ($(this).hasClass('selected')) {

                // add tick to select to show an option has been selected 
                $('.type-select').addClass('picked');

                $('#v-pills-brand').load("/ajax/home_loadbrand?masterid="+masterid+"&catid="+catid, function(){
                    // remove disabled state of next option
                    $('.type-select').removeClass('disabled');    
                }); 

                // remove disabled state of next option
                $('.brand-select').removeClass('disabled');
                
                // remove active state from current select
                $('.type-select').removeClass('active'); 

                // add active state to next select
                $('.brand-select').addClass('active');                

                // open next menu
                $('#v-pills-brand').tab('show');

                // close current menu
                $('#v-pills-type').tab('dispose').removeClass('active'); 

                // replace text with selected
                var str = $('.type-option.selected h4').text(); 
                $('.type-select span').text(str);  
                
                // reset brand options
                $('.brand-option').removeClass('selected');
                $('.brand-select').removeClass('picked'); 
                $('.brand-select span').text('Select Brand');                  
            }
        }); 
        
        

    });    

    $('.brand-option').click(function(e){
        e.preventDefault();
        $(this).toggleClass('selected');
        $('#v-pills-type').tab('dispose');

        var brandid = $(this).data('brandid');
        console.log('brandid: '+brandid);

        $("#hmbrandId").val(brandid);
        catid = $("#hmcatId").val();
        masterid = $("#hmmasterId").val();
         
        // closes menu and background effects once option is selected
        $(".tab-pane.active").removeClass('active');
        $(".filter-holder .nav-link.active").removeClass('active');
        $('.filter-fade').removeClass('active');
        $('.carousel-filter').removeClass('active');
        $('.slide-bg').removeClass('blur');                

        if($('.brand-option.selected').length){
            $('.brand-option').not(this).removeClass('selected'); 
            $('.brand-select span').empty();             
        }

        else{
            // remove tick               
            $('.brand-select').removeClass('picked');

            // reset select text
            $('.brand-select span').text(brand_text); 
        } 

        $('.brand-option').each(function() {
            if ($(this).hasClass('selected')) {
                // add tick to select to show an option has been selected 
                $('.brand-select').addClass('picked');

                // replace text with selected
                var str = $('.brand-option.selected h4').text(); 
                $('.brand-select span').text(str);                  
            }
        });
        
    });   

    // price range slider
    $("#slider").slider({ min: 0, max: 5000, range: true, values: [0, 5000], step: 500, slide: function( event, ui ) {
        
        $( "#pricefrom" ).val(ui.values[0]);
        $( "#priceto" ).val(ui.values[1]);
        $.post( "ajax/getprodno", { masterId: $("#hmmasterId").val(), catId: $("#hmcatId").val(), branId: $("#hmbrandId").val(), pricefrom:  $( "#pricefrom" ).val(), priceto: $( "#priceto" ).val() }, function( data ) {
            $("#searchprodbutton").html("<span>Search "+data+" Products</span>");
        });
    } }).slider("pips", {rest: "label", step: 2}).slider("float");


    // add carousel backdrop when tab is open

    $('a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
        $('.filter-fade').addClass('active');
        $('.carousel-filter').addClass('active');
        $('.slide-bg').addClass('blur');
    });
     

    // Close Buttons
    $(document).on('click', '.close-tab', function (e) {
        closeActiveTab();
    });
    






    // product gallery ------------------------

    // handles the carousel thumbnails
    $('[id^=carousel-selector-]').click(function () {
        var id_selector = $(this).attr("id");
        var id = id_selector.substr(id_selector.length - 1);
        id = parseInt(id);
        $('#prodSlide').carousel(id);
        $('[id^=carousel-selector-]').removeClass('selected');
        $(this).addClass('selected');
    });

    // when the carousel slides, auto update
    $('#prodSlide').on('slide.bs.carousel', function (e) {
        var id = $('.item.active').data('slide-number');
        id = parseInt(id);
        $('[id^=carousel-selector-]').removeClass('selected');
        $('[id=carousel-selector-' + id + ']').addClass('selected');
    });


    $('a[data-rel^=lightcase]').lightcase({ 
        swipe: true,
        maxWidth: 1920,
        maxHeight: 1000,
        shrinkFactor: .9,
        transition: "elastic" 
    });


    // Quantity plus/minus
    //$('.count').prop('disabled', true);

    $(document).on('click','.plus',function(){
        $('.count').val(parseInt($('.count').val()) + 1 );
    });

    $(document).on('click','.minus',function(){
        $('.count').val(parseInt($('.count').val()) - 1 );
            if ($('.count').val() == 0) {
                $('.count').val(1);
        }
     });





    // account menu mobile navigation
    $("#account_menu_select").change(function (e) {
        e.preventDefault();
        window.location = $(this).val();
	});  



    //Recently viewed products
    $("#recent-view").click(function (e) {
        e.preventDefault();
        var $this = $(this);
        var isShown = $this.attr("data-is-shown");
        if (isShown === "false") {
            //Show it
            $("#bottom-bar").addClass('open'); 
            $this.attr("data-is-shown", "true");
            $this.addClass("recent-view-close");
            
        } else {
            //Hide it
            hideRecentList();
        };
    });

    //When users leaves the recent product list, close it.
    $("#bottom-bar").mouseleave(function () {
        var isShown = $("#recent-view").attr("data-is-shown");
        if (isShown === "true") {
            hideRecentList();
        };
    });

    function hideRecentList() {
        $("#recent-view").attr("data-is-shown", "false");
        $("#bottom-bar").toggleClass('open');
        $("#recent-view").removeClass("recent-view-close");        
    }    

    // slick carousel
    $('.recent-prods').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                }
            },            
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                }
            }                    
        ]                
    });    

    $('.product-thumbs').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                }
            },            
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                }
            }                    
        ]                
    });    

    $('.category-carousel').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2,
                }
            }                   
        ]                
    });    


});

//quick add to cart code 
function showResponsespare(responseText, statusText, xhr, $form, pId)  { 
    console.log('OK'); 
     var arr = $.parseJSON(responseText);
     var cart = $('#headerTotal');
                 var imgtodrag = $("#AddToCart_"+pId);

                     var imgclone = imgtodrag.clone()
                         .offset({
                             top: imgtodrag.offset().top,
                             left: imgtodrag.offset().left
                         })
                         .css({
                             'opacity': '0.5',
                             'position': 'absolute',
                             'height': '150px',
                             'width': '150px',
                             'z-index': '100'
                         })
                         .appendTo($('body'))
                         .animate({
                             'top': cart.offset().top,
                             'left': cart.offset().left
                         }, 1000, 'easeInOutExpo', function(){
                             $("#headerTotal").html(arr.headerTotal);
                             $("#headerQuant").html(arr.headerQuant);
                             $("#modquant").html(arr.headerQuant);
                             $("#modtotal").html(arr.headerTotal);

                         });

                     //setTimeout(function () {
                     //    cart.effect("bounce", {
                     //        times: 2
                     //    }, 500, function () {
                             //$("#headerQuant").html(arr.headerQuant);
                             
                     //    });
                     //}, 1500);

                     imgclone.animate({
                         'width': 0,
                         'height': 0
                     }, function () {
                         $(this).detach();
                         // animate the go to checkout popup
                         
                         //$('#cartmodal').modal('show');
                     });


 }   


function addspare(pId){
    $.post( "/addtocart", { pid: pId, ajax: "true", quant: 1 }, function(data, statusText, xhr){
        showResponsespare(data, statusText, xhr, $('#productform'), pId);
    }); 
}
window.addspare = addspare;


// close active tab when clicking on anything but the tab and it's children
$(document).mouseup(function(e) 
{
    var container = $("#homepage-carousel .tab-pane.active");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) 
    {
        $(".filter-holder .nav-link.active").removeClass('active');
        $('.filter-fade').removeClass('active');
        $('.carousel-filter').removeClass('active');
        $('.slide-bg').removeClass('blur');
        container.removeClass('active');
    }
});



// Add custom scroll bar to the filters when needed
var filterTab = document.querySelector('.filter-tab-pane');

if(filterTab){
    OverlayScrollbars(document.querySelector('.filter-tab-pane'), {
        overflow: {
        x: 'hidden',
        },
        scrollbars: {
        visibility: 'auto',
        autoHide: 'leave',
        }
    });
}

const prodFilterCol = document.querySelector(".filter-col")

if(prodFilterCol){

    // Resize sticky filter button when it becomes sticky
    window.onscroll = function() { 
        //const el = document.querySelector(".filter-col")

        
            const observer = new IntersectionObserver( 
            ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
            { threshold: [1] }
            );
        
            observer.observe(prodFilterCol);
        
    }
}


// Add custom scroll bar to the filters when needed
var filterCollapse = document.querySelector('.filter-collapse');

if(filterCollapse){
    OverlayScrollbars(document.querySelector('.filter-collapse'), {
        overflow: {
        x: 'hidden',
        },
        scrollbars: {
        visibility: 'auto',
        autoHide: 'leave',
        }
    });
}





