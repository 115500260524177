window.addEventListener("load", function(){

    window.cookieconsent.initialise({
        "container": document.getElementsByTagName("footer")[0],
        position: "bottom left",
        revokable : false,        
        theme: "classic",
        onPopupOpen : function () {

            $(".cc-window").wrapInner("<div class='cc-window-wrapper'></div>");

            $popUpHeight = $('.cc-banner').outerHeight();

            // add padding to footer when notice is open
            $('footer').css('padding-bottom', $popUpHeight + 'px');

            // prevent notice from 'floating' on mobiles/tablets
            //$(".cc-window").removeClass("cc-floating").addClass("cc-banner");
        },
        onPopupClose : function () {
            // remove padding from footer when notice is closed
            $('footer').css('padding-bottom', 0);
        },                
        "content": {
            "message": "This website uses cookies to give you the best experience. By using our website you agree to our cookie policy",
            "dismiss": "<span>Accept &amp; Close</span>",
            "link": "Learn more",
            "href": "/cookies"
        }
    })
});
